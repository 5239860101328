/*----------------------------------------------------------------*/
/*  Print
/*----------------------------------------------------------------*/
@media all {
    /* Never show page breaks in normal view */
    .page-break-after,
    .page-break-before {
        display: none;
    }
}

@media print
{
    html, body
    {
        height: auto !important;
        overflow: initial !important;
    }

    .page-break-after
    {
        display: block;
        page-break-after: always;
        position: relative;
    }

    .page-break-before
    {
        display: block;
        page-break-before: always;
        position: relative;
    }

    @page
    {
        margin : 1cm;
        size   : auto;
    }

    * {
        -webkit-transition: none !important;
        transition: none !important;
    }

    /* General styles */

    #toolbar,
    #footer,
    #navbar,
    #settings,
    #layout .ps > .ps__rail-x,
    #layout .ps > .ps__rail-y
    {
        display: none !important;
    }

    #layout .ps
    {
        overflow: visible !important;
    }
}

@page
{
    margin : 1cm;
    size   : auto;
}